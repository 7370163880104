 import {Center} from "@chakra-ui/react";
 import * as React from "react";

export default function Card(props) {
    return (
        <Center
            width="100%"
            height={props.height ? props.height : "auto"}
            backgroundColor="background"
            rounded="3xl"
            padding="40px"
        >
            {props.children}
        </Center>
    );
}