import * as React from "react";
import {Heading} from "@chakra-ui/react";

export function BigHeading(props) {
    return (
        <Heading size="3xl" textAlign={props.textAlign ?? "center"}>
            {props.children}
        </Heading>
    );
}

export function MediumHeading(props) {
    return (
        <Heading size="xl" textAlign={props.textAlign ?? "center"} fontWeight="semibold">
            {props.children}
        </Heading>
    );
}

export function SmallHeading(props) {
    return (
        <Heading size="lg" textAlign={props.textAlign ?? "left"} fontWeight="semibold">
            {props.children}
        </Heading>
    );
}

export function Subheading(props) {
    return (
        <Heading size="lg" color="secondary" textAlign={props.textAlign ?? "center"} fontWeight="medium">
            {props.children}
        </Heading>
    );
}

export function BodyText(props) {
    return (
        <Heading size="sm" color="secondary" textAlign={props.textAlign ?? "center"} fontWeight="medium" lineHeight="2">
            {props.children}
        </Heading>
    );
}