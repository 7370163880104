import React from "react";
import {Helmet} from "react-helmet";

export default function SiteHelmet(props) {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description}/>

            {/*Icons*/}

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="theme-color" content="#ffffff"/>

            {/*FONTS*/}

            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Quicksand:wght@400;500;600&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Quicksand:wght@400;500;600&display=swap" />

            {/*COLOR SCHEME*/}
            <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
            <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: dark)" />

            {/*Analytics*/}
            {/*<script data-host="https://microanalytics.io" data-dnt="false" src="https://microanalytics.io/js/script.js" id="ZwSg9rf6GA" async defer />*/}
        </Helmet>
    )
}