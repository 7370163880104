import React from "react";
import {ChakraProvider} from "@chakra-ui/react";
import theme from "../style/theme";

export default function ThemeProvider(props) {
    return (
        <ChakraProvider
            theme={theme}
        >
            {props.children}
        </ChakraProvider>
    );
}