import {extendTheme} from "@chakra-ui/react";

const theme = {
    colors: {
        siteBlue: "#3A75C2",
        siteGreen: "#51AF63",
        secondary: "#636363",
        background: "#F7F7F7"
    },
    fonts: {
        body: "Quicksand, sans-serif",
        heading: "Quicksand, sans-serif",
        mono: "Menlo, monospace",
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
    },
};

export default extendTheme(theme);