import * as React from "react";
import { Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {BodyText} from "./text";

export function InlineLink(props) {
    return (
        <Link href={props.href}>
            {props.children}
            <ExternalLinkIcon mx="2px"/>
        </Link>
    )
}

export function BlockLink(props) {
    return (
        <Link href={props.href}>
            <BodyText>{props.children}</BodyText>
        </Link>
    )
}