import * as React from "react";
import {BodyText} from "./text";
import {BlockLink} from "./inlineLink";
import {VStack, Wrap, WrapItem} from "@chakra-ui/react";

export default function FooterText() {
    return (
        <VStack align="center" spacing="20px">
            <Wrap spacing="20px" align="center">
                <WrapItem>
                    <BlockLink href="https://runwalk.benrobinson.dev/">Home</BlockLink>
                </WrapItem>
                <WrapItem>
                    <BlockLink href="https://runwalk.benrobinson.dev/support">Support</BlockLink>
                </WrapItem>
                <WrapItem>
                    <BlockLink href="https://runwalk.benrobinson.dev/terms">Terms</BlockLink>
                </WrapItem>
                <WrapItem>
                    <BlockLink href="https://runwalk.benrobinson.dev/privacy">Privacy</BlockLink>
                </WrapItem>
            </Wrap>
            <BodyText>
                Apple Watch is a registered trademark of Apple Inc., registered in the US and other countries.
            </BodyText>
            <BodyText>
                (c) 2022 Benjamin Robinson. All rights reserved.
            </BodyText>
        </VStack>
    );
}