import React from "react";
import {Flex, Box} from "@chakra-ui/react";

export function PageWrap(props) {
    const { bgColor, minHeight, ...rest } = props;

    return (
        <Box
            bgColor={bgColor}
            minHeight={minHeight}
            {...rest}
        >
            <Flex
                direction="column"
                align="center"
                maxWidth={{ xl: "1200px" }}
                margin="0 auto"
            >
                {props.children}
            </Flex>
        </Box>
    )
}

export function ReadableWrap(props) {
    const { bgColor, minHeight, ...rest } = props;

    return (
        <Box
            bgColor={bgColor}
            minHeight={minHeight}
            {...rest}
        >
            <Flex
                direction="column"
                align="center"
                // margin="0 auto"
                marginLeft={["3%", "6%", "10%", "16%", "22%"]}
                marginRight={["3%", "6%", "10%", "16%", "22%"]}
                maxWidth={{ xl: "1200px" }}
            >
                {props.children}
            </Flex>
        </Box>

    )
}